import React from 'react';
import styled from 'styled-components';

const Button = ({ text, children, classes, styles, handleClick }) => (
	<Component
		type="button"
		data-testid="button-element"
		className={`button-element ${classes}`}
		onClick={() => handleClick && handleClick()}
		styles={styles}
	>
		{text}
		{children}
	</Component>
);

export default Button;

const Component = styled.button`
	${props => props?.styles}
`;
